<script>
	export let source = 'facebook';
</script>

<!-- <div class="logos">
  {#each sources?.filter((s) => s != 'spotify') as company}
    <div style="position: relative">
      <img
        id={company}
        class={'logo ' + (source == company ? 'selected' : '')}
        src={`/logos/${company}_logo.svg`}
        alt={company + ' icon'}
        on:click={() => (source = company)} />
    </div>
  {/each}
</div>
<div class="width-full text-center mb-4">
  Click on the logo of the data-source you want to analyse in Magicflow
</div> -->

<!-- Facebook -->
{#if source == 'facebook'}
	<p class="content">
		Before you go to your settings (linked below), here's how to make sure you request the data in
		the right format:
	</p>
	<ol>
		<li>
			<div>
				<p>Select the format: <b>JSON</b></p>
				<img src="tutorial/selectJSON.webp" alt="select JSON" />
			</div>
		</li>
		<li>
			<div>
				<p>Untick <b>Photos and Videos</b> from the list of data</p>
				<img src="tutorial/untickPhotos.webp" alt="untick photos" />
			</div>
		</li>
		<li>
			<div>
				<p>Choose <b>Media quality: Low</b></p>
				<img src="tutorial/lowQuality.webp" alt="low quality" />
			</div>
		</li>
		<li>
			<p>Click <b>"Create File"</b></p>
		</li>
	</ol>
	<div class="content">
		<p><b>Ok. Time to download it. Remember the right format!</b></p>
		<p>
			Go to
			<a href="https://facebook.com/dyi" target="_blank"> Download Your Information </a>
			within your Facebook Settings (Log in if you need to).
		</p>
		<p>You should see:</p>
		<div class="picture">
			<a href="https://facebook.com/dyi" target="_blank">
				<img src="/tutorial/downloadFacebook.webp" alt="Settings" />
				<div class="container">Facebook settings</div>
			</a>
		</div>
	</div>
	<p class="content">
		It may take a while (between 20mins and 1 hour) for the data to be ready, especially if the file
		is large. Facebook will notify you when the file is ready.
	</p>
	<a href="https://facebook.com/dyi" target="_blank">
		<button>Download Your Data</button>
	</a>
	<!-- FB done, now Google -->
	<!-- Google -->
{:else if source == 'google'}
	<div class="content">
		<p>
			<b> Make sure you download it in the largest filesize (10GB+) and export every 2 months! </b>
			<br />
			Go to
			<a href="https://takeout.google.com/settings/takeout" target="_blank">
				Your Google Data takeout
			</a>
			within your Google Settings (Log in if you need to).
		</p>
		<p>You should see:</p>
		<div class="picture">
			<a href="https://takeout.google.com/settings/takeout" target="_blank">
				<img src="/tutorial/downloadGoogle.webp" alt="Settings" />
			</a>
			<div class="container">
				<p>Google Takeout page</p>
			</div>
		</div>
	</div>
	<a href="https://takeout.google.com/settings/takeout" target="_blank">
		<button>Download Your Data</button>
	</a>
	<!-- Google done, now Twitter -->
	<!-- Twitter -->
{:else if source == 'twitter'}
	<div class="content">
		<p>
			<b>
				You can request a ZIP file with an archive of your Twitter data. You’ll get an in-app
				notification when the archive of your data is ready to download.
			</b>
			<br />
			Go to
			<a href="https://twitter.com/settings/your_twitter_data" target="_blank">
				Your Twitter data
			</a>
			within your Twitter Settings (Log in if you need to).
		</p>
		<p>You should see:</p>
		<div class="picture">
			<a href="https://twitter.com/settings/your_twitter_data" target="_blank">
				<img src="/tutorial/downloadTwitter.webp" alt="Settings" />
			</a>
			<div class="container">
				<p>Twitter Takeout page</p>
			</div>
		</div>
	</div>
	<a href="https://twitter.com/settings/your_twitter_data" target="_blank">
		<button>Download Your Data</button>
	</a>
	<!-- Twitter done, now LinkedIn -->
	<!-- LinkedIn -->
{:else if source == 'linkedin'}
	<div class="content">
		<p>
			<b>
				Request your full ZIP file with an archive of your LinkedIn data. You’ll get an in-app
				notification and an email when the archive of your data is ready to download.
			</b>
			<br />
			Make sure to choose Download larger archive! Go to
			<a href="https://www.linkedin.com/psettings/member-data" target="_blank">
				Your LinkedIn data
			</a>
			within your LinkedIn Settings (Log in if you need to).
		</p>
		<p>You should see:</p>
		<div class="picture">
			<a href="https://www.linkedin.com/psettings/member-data" target="_blank">
				<img src="/tutorial/downloadLinkedin.webp" alt="Settings" />
			</a>
			<div class="container">
				<p>LinkedIn Takeout page</p>
			</div>
		</div>
	</div>
	<a href="https://twitter.com/settings/your_twitter_data" target="_blank">
		<button>Download Your Data</button>
	</a>
	<!-- LinkedIn done, now Instagram -->
	<!-- Instagram -->
{:else if source == 'instagram'}
	<div class="content">
		<p>
			<b>
				Request your full ZIP file with an archive of your Instagram data. You’ll get an in-app
				notification and an email when the archive of your data is ready to download.
			</b>
			Make sure to choose JSON format! Go to
			<a href="https://www.instagram.com/download/request/" target="_blank">
				Your Instagram data
			</a>
			within your Instagram Settings (Log in if you need to).
		</p>
		<p>You should see:</p>
		<div class="picture">
			<a href="https://www.instagram.com/download/request/" target="_blank">
				<img src="/tutorial/downloadInstagram.webp" alt="Instagram Settings" />
			</a>
			<div class="container">
				<p>Instagram Takeout page</p>
			</div>
		</div>
	</div>
	<a href="https://twitter.com/settings/your_twitter_data" target="_blank">
		<button>Download Your Data</button>
	</a>
{/if}

<style>
	/* .logos {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: fit-content;
		margin: 3vh auto 2vh;
		width: 80%;
	}
	img.logo {
		margin: 0px;
		padding: 0px;
		height: 40px;
		width: 40px;
		opacity: 0.4;
		box-shadow: 'none';
		cursor: pointer;
	}
	img.logo.selected {
		opacity: 1;
		box-shadow: '0px 4px 15px 0px #2D303A16';
	}
	.beta {
		position: absolute;
		top: -40%;
		left: 70%;
		width: 100%;
		color: rgb(255, 0, 115);
		opacity: 0.6;
		font-family: monospace;
	} */
	div.picture {
		display: block;
		margin: 20px auto 40px;
		border-radius: 5px;
		width: 70%;
		background-color: white;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	}
	div.picture div {
		padding: 1vh;
	}
	div.picture:hover {
		border-color: #1e90ff;
	}
	li div {
		display: flex;
	}
	img {
		max-width: 100%;
	}
	li img {
		width: 50%;
		margin: 2vh;
		margin-left: auto;
		height: 100%;
	}
</style>
