<script>
	import DownloadTutorial from '../tutorials/DownloadTutorial.svelte';
	import Modal from '../Modal.svelte';
	let selected;
	export let sources = ['facebook', 'google', 'twitter', 'linkedin', 'instagram'];
	export let sourceTimes = {
		facebook: ['2 minutes', '2-4 hours'],
		google: ['5 minutes', '6-24 hours'],
		twitter: ['2 minutes', '4-8 hours'],
		linkedin: ['3 minutes', '4-8 hours'],
		instagram: ['3 minutes', '24-48 hours']
	};
</script>

<div
	class="grid max-w-xl w-full m-auto gap-6 p-2"
	style="grid-template-columns: repeat(auto-fit, minmax(115px, 1fr)) !important;"
>
	{#each sources as source}
		<card
			class="capitalize bg-black flex flex-col items-center gap-4 font-semibold cursor-pointer"
			on:click={() => (selected = source)}
		>
			<img class="h-10 w-10" src="/logos/{source}_logo.svg" alt="{source} logo" />
			<div>
				<div class="text-xs text-gray-400 text-center uppercase my-1 font-semibold">
					Time to request
				</div>
				<div class="flex items-center gap-2 font-medium text-sm">
					<svg
						class="w-6 h-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136
                7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
						/>
					</svg>
					{sourceTimes[source]?.[0]}
				</div>
			</div>

			<div>
				<div class="text-xs text-gray-400 text-center uppercase my-1 font-semibold">Wait time</div>
				<div class="flex items-center gap-2 font-medium text-sm">
					<svg
						class="w-6 h-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
							d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
					{sourceTimes[source][1]}
				</div>
			</div>
		</card>
	{/each}
</div>

{#if selected}
	<Modal options={{ topLevel: true }} bind:show={selected}>
		<DownloadTutorial source={selected} />
	</Modal>
{/if}
